import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"


import { Row, Col } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap-grid.min.css'

import Layout from "../components/layout"


const HomepageTemplate = ({data, pageContext: {lang, currentPath}}) => console.log(data) || (
    
  <Layout wpLang={lang} currentPath={currentPath}  pageTitle={data.wordpressPage.yoast_title} pageMeta={data.wordpressPage.yoast_meta} featuredImagePath="above-min.png">
  <Row className="halloween">
    <div className="hallotext">
    <h1>No trick, all treat.</h1>
<p>Share a Dagcoin Gift Card this Halloween…</p>
</div>
  <div className="pumpkin"><Img fluid={data.pumpkin.childImageSharp.fluid}/></div>
  <Img fluid={data.bgabove.childImageSharp.fluid}/>
  <a href="https://app.giftcard.dagcoin.org/?type=physical">Shop gift cards</a>
  </Row>
 {/* <Row className="limited-time-offer"> 
 <div className="hero__text-holder home-top">
                <h1 className="hero__title">Limited time offer!</h1>
                 <p className="hero__subtitle">A nice copy for clients to be more eager to buy</p> 
              </div>
              <Row className="limited-below">
    <Col>
    <div className="colheader">
    <div className="col-img"><Img fluid={data.GiftImage.childImageSharp.fluid}/></div>
    <h3>50 DAG</h3>
    </div>
    <ul>
      <li>Get a 50 DAG gift card</li>
      <li>Get an extra gift card worth 10 DAGS <strong>for free</strong></li>
    </ul>
    <div className="offerone"><Img fluid={data.GiftImage.childImageSharp.fluid}/></div>
    <a href="https://app.giftcard.dagcoin.org/?type=physical">BUY</a>
    </Col>
    <Col>
    <div className="colheader">
    <div className="col-img"><Img fluid={data.GiftImage.childImageSharp.fluid}/></div>
    <h3>100 DAG</h3>
    </div>
    <ul>
      <li>Get a 100 DAG gift card</li>
      <li>Get three extra gift cards worth 10 DAGS each <strong>for free</strong></li>
    </ul>
    <div className="offertwo"><Img fluid={data.GiftImage.childImageSharp.fluid}/><Img fluid={data.GiftImage.childImageSharp.fluid}/><Img fluid={data.GiftImage.childImageSharp.fluid}/></div>
    <a href="https://app.giftcard.dagcoin.org/?type=physical">BUY</a>
    </Col>
    <Col>
    <div className="colheader">
    <div className="col-img"><Img fluid={data.GiftImage.childImageSharp.fluid}/></div>
    <h3>250 DAG</h3>
    </div>
    <ul>
      <li>Get a 250 DAG gift card</li>
      <li>Get an extra gift card worth 50 DAGS <strong>for free</strong></li>
    </ul>
    <div className="offerthree"><Img fluid={data.GiftImage.childImageSharp.fluid}/></div>
    <a href="https://app.giftcard.dagcoin.org/?type=physical">BUY</a>
    </Col>
  </Row>
  </Row>        */}
<Row className="hero home-above">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hero__text-holder home-top">
                <h1 className="hero__title" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.hero_h1 }} />
                <p className="hero__subtitle" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.hero_p }} />
              </div> 
            <div className="home-middle">
                <div className="col-lg-6">
                    <a href="https://app.giftcard.dagcoin.org/?type=egift">
                    <Img fluid={data.SendAs.childImageSharp.fluid}/>
                    <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.send_desc }} />
                    <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.send }} />
                    </a>
       
                </div>
                <div className="col-lg-6">
                    <a href="https://app.giftcard.dagcoin.org/?type=physical">
                    <Img fluid={data.GetAs.childImageSharp.fluid}/>
                    <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.get_desc }} />
                    <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.get_title }} />
                    </a>
                </div>
            </div>
            </div>
              <div className="col-lg-12">
                <div className="home-bottom">
                    <div className="col-lg-4">
                        <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.choose_title }} />
                        <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.choose_desc }} />
                    </div>     
                    <div className="col-lg-4">
                        <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.buy_title }} />
                        <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.buy_desc }} />
                    </div> 
                    <div className="col-lg-4">
                        <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.gift_title }} />
                        <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.gift_desc }} />
                    </div> 
                </div>
            </div>
          </div>
        </div>
      </Row>
        <Row className="way">
            <div className="col-lg-12">
                <div className="col-lg-6">
                <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.way_title }} />
                <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.way_desc }} />
                <a href="https://dagcoin.org">{ data.wordpressPage.acf.learn_more }</a>
                </div>
            </div>
            <div class="hand-way"><Img fluid={data.Hand.childImageSharp.fluid}/></div>
        </Row>
        <Row className="four-items">
            <div className="col-lg-12">
                <div class="col-lg-3">
                    <Img fluid={data.Custom_one.childImageSharp.fluid}/>
                    <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.home_box_1 }} />
                </div>
                <div class="col-lg-3">
                    <Img fluid={data.Custom_two.childImageSharp.fluid}/>
                    <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.home_box_2 }} />
                </div>
                <div class="col-lg-3">
                    <Img fluid={data.Custom_three.childImageSharp.fluid}/>
                    <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.home_box_3 }} />
                </div>
                <div class="col-lg-3">
                    <Img fluid={data.Custom_four.childImageSharp.fluid}/>
                    <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.home_box_4 }} />
                </div>
            </div>
        </Row>
        <Row className="growth">
            <div className="col-lg-12">
                <div className="col-lg-6">
                    <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.growth_title }} />
                    <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.growth_section_description }} />
                    <a href="https://app.giftcard.dagcoin.org/?type=egift">{ data.wordpressPage.acf.learn_more }</a>
                </div>
                
                <div className="col-lg-6 boximage">
                  <Img fluid={data.GiftBox.childImageSharp.fluid}/>
                </div>
            </div>
        </Row>  
        <Row className="prefooter">
        <div className="col-lg-12">
                    <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.give_title }} />
                    <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.give_desc }} />
                    <a href="https://app.giftcard.dagcoin.org/?type=egift">{ data.wordpressPage.acf.send }</a>
                    <a href="https://app.giftcard.dagcoin.org/?type=physical" className="home-second-pop">{ data.wordpressPage.acf.get_title }</a>
            </div>            
        </Row>
  </Layout>
)

export const query = graphql`
  query($lang: String) {
    wordpressPage(
      slug: {eq: "home"}
      wpml_current_locale: {eq: $lang}
    ) {
    acf {
      growth_section_description
      buy_desc
      buy_title
      way_title
      gift_title
      way_desc
      give_desc
      send_desc
      send
      order_your_giftcard
      home_box_4
      learn_more
      home_box_3
      home_box_2
      hero_p
      home_box_1
      hero_h1
      give_title
      growth_desc
      growth_title
      gift_desc
      get_title
      get_desc
      choose_title
      choose_desc
    }
      title
      yoast_meta {
        name
        content
        property
      }
      yoast_title
        wpml_current_locale
    }
    Videoimg: file(relativePath: { eq: "video-min.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    pumpkin: file(relativePath: { eq: "pumpkin.png" }) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgabove: file(relativePath: { eq: "bgabove.png" }) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    GiftImage: file(relativePath: { eq: "giftcard.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SendAs: file(relativePath: { eq: "mac2.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    GiftBox: file(relativePath: { eq: "gift-box-PS4UP4U.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    GetAs: file(relativePath: { eq: "card2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    play: file(relativePath: { eq: "play-button.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    right: file(relativePath: { eq: "right.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Hand: file(relativePath: { eq: "hand.png" }) {
      childImageSharp {
        fluid (quality: 100){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    Custom_one: file(relativePath: { eq: "1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Custom_two: file(relativePath: { eq: "2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Custom_three: file(relativePath: { eq: "3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }    
    Custom_four: file(relativePath: { eq: "4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    arrow_right: file(relativePath: { eq: "right.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }


  }
`
export default HomepageTemplate